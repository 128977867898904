/// <reference path="o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as ApiFileStrategyModule from 'o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts';

import type {
    IApiFileRequestViewOperationOptions,
    IApiFileRequestDownloadOperationOptions,
    IApiFileRequestUploadOperationOptions,
    IApiFileRequestChunkUploadOperationOptions,
    IFileCrudPdfViewOperationOptions,
    IApiFileRequestChunkUploadInitiateOperationOptions,
    IApiFileRequestChunkUploadSetCrcOperationOptions,
    IFileCrudPdfDownloadOperationOptions
} from 'o365.pwa.declaration.sw.apiRequestOptions.ApiFileRequestOptions.d.ts';

import type { Response, Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    const { ApiFileRequestOptions } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.apiRequestOptions.ApiFileRequestOptions.d.ts')>('o365.pwa.modules.sw.apiRequestOptions.ApiFileRequestOptions.ts');
    const { FileCrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.FileCrudHandler.d.ts')>("o365.pwa.modules.sw.FileCrudHandler.ts");
    const { CrudHandler } = self.o365.importScripts<typeof import('o365.pwa.declaration.sw.CrudHandler.d.ts')>("o365.pwa.modules.sw.CrudHandler.ts");

    class ApiFileStrategy extends self.workbox.strategies.Strategy implements ApiFileStrategyModule.ApiFileStrategy {
        private readonly apiFileRoute: ApiFileStrategyModule.ApiFileRoute;

        constructor(options: ApiFileStrategyModule.IApiPwaStrategyOptions) {
            super(options);

            this.apiFileRoute = options.apiFileRoute;
        }

        async _handle(request: Request, _handler: typeof self.workbox.strategies.StrategyHandler): Promise<Response> {
            try {
                const options = await ApiFileRequestOptions.fromRequest(request, this.apiFileRoute);
                const parsedOptions = options.parsedOptions;

                switch (this.apiFileRoute) {
                    case 'VIEW': {
                        const options = parsedOptions.options as IApiFileRequestViewOperationOptions;
                        const appRecord = await ApiFileStrategy.getAppRecords(options);

                        if (!appRecord?.FileRef) {
                            return new Response(JSON.stringify({ error: "No fileRef provided." }), {
                                status: 400,
                                statusText: "Bad Request",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }
                        const viewResponse = await FileCrudHandler.handleView({ FileRef: appRecord?.FileRef });

                        if (!viewResponse) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }

                        return ApiFileStrategy.handleResponse(viewResponse.dataAsBlob, viewResponse.mimeType, "inline;");
                    }
                    case 'DOWNLOAD': {
                        const options = parsedOptions.options as IApiFileRequestDownloadOperationOptions;
                        const appRecord = await ApiFileStrategy.getAppRecords(options);
                        const downloadResponse = await FileCrudHandler.handleDownload({ FileRef: appRecord.FileRef });

                        if (!downloadResponse) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }

                        return ApiFileStrategy.handleResponse(downloadResponse.dataAsBlob, downloadResponse.mimeType, "attachment", downloadResponse.filename);
                    }
                    case 'UPLOAD': {
                        const uploadBody = parsedOptions.options as IApiFileRequestUploadOperationOptions;
                        let newAppRecord;
                        let newFileRecord;
                        let primKeyWasNull = false;

                        if (uploadBody.primKey) {
                            const appRecord = await ApiFileStrategy.getAppRecords(uploadBody);
                            if (appRecord) {
                                const record = await FileCrudHandler.handleView({ FileRef: appRecord.FileRef });
                                const filePrimKey = record && record.originalRef === null ? self.crypto.randomUUID() : record!.primKey;

                                newFileRecord = ApiFileStrategy.createNewFileRecord(filePrimKey, uploadBody, record?.originalRef!);
                                newAppRecord = ApiFileStrategy.createNewAppRecord(filePrimKey, uploadBody, appRecord.PrimKey);
                            } else {
                                primKeyWasNull = true;
                                const newFileRef = self.crypto.randomUUID();
                                newFileRecord = ApiFileStrategy.createNewFileRecord(newFileRef, uploadBody);
                                newAppRecord = ApiFileStrategy.createNewAppRecord(newFileRef, uploadBody, uploadBody.primKey);
                            }
                        } else {
                            const newFileRef = self.crypto.randomUUID();

                            newFileRecord = ApiFileStrategy.createNewFileRecord(newFileRef, uploadBody);
                            newAppRecord = ApiFileStrategy.createNewAppRecord(newFileRef, uploadBody, self.crypto.randomUUID().toUpperCase());
                        }

                        if (newAppRecord && newFileRecord) {
                            const newApp = uploadBody.primKey && !primKeyWasNull ? await CrudHandler.handleUpdate(newAppRecord) : await CrudHandler.handleCreate(newAppRecord);
                            const newFile = await FileCrudHandler.handleUpload(newFileRecord);

                            return new Response(Object.assign({ newApp, newFile }), {
                                status: 200,
                                statusText: 'OK.',
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        } else if (newFileRecord) {
                            const newFile = await FileCrudHandler.handleUpload({ ...newFileRecord, appID: parsedOptions.appId });

                            return new Response(Object.assign({ newFile }), {
                                status: 200,
                                statusText: 'OK',
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }

                        return new Response(JSON.stringify({}), {
                            status: 500,
                            statusText: 'Something went wrong...',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });
                    }
                    case 'OLD-CHUNK-UPLOAD': {
                        const chunkUploadOptions = parsedOptions.options as IApiFileRequestChunkUploadOperationOptions;
                        const response = await FileCrudHandler.handleChunkOldUpload({ ...chunkUploadOptions, appID: parsedOptions.appId }, parsedOptions.headers);
                        if (!response) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }
                        return new Response(JSON.stringify(response), {
                            status: 200,
                            statusText: 'OK',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });

                    }
                    case 'CHUNK-UPLOAD': {
                        const chunkUploadOptions = parsedOptions.options as IApiFileRequestChunkUploadOperationOptions;
                        const response = await FileCrudHandler.handleChunkUpload({ ...chunkUploadOptions, appID: parsedOptions.appId }, parsedOptions.headers);
                        if (!response) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }
                        return new Response(JSON.stringify(response), {
                            status: 200,
                            statusText: 'OK',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });

                    }
                    case 'CHUNK-INITIATE': {
                        const chunkUploadOptions = parsedOptions.options as IApiFileRequestChunkUploadInitiateOperationOptions;

                        const record = await CrudHandler.handleCreate({
                            ...chunkUploadOptions,
                            appIdOverride: chunkUploadOptions.appId,
                            databaseIdOverride: chunkUploadOptions.databaseId,
                            objectStoreIdOverride: chunkUploadOptions.objectStoreId
                        }, parsedOptions.headers);

                        const fileRecord = await FileCrudHandler.handleUpload(undefined, record.FileRef, options.parsedOptions.appId);

                        if (!fileRecord) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }
                        const response = {
                            action: "StartUpload",
                            uploadRef: fileRecord.primKey
                        };
                        return new Response(JSON.stringify(response), {
                            status: 200,
                            statusText: 'OK',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });
                    }
                    case 'CHUNK-SET-CRC32': {
                        const chunkUploadOptions = parsedOptions.options as IApiFileRequestChunkUploadSetCrcOperationOptions;
                        const { action, fileRef } = await FileCrudHandler.handleChunkSetCRC({ ...chunkUploadOptions });

                        const response = { action: action };
                        return new Response(JSON.stringify(response), {
                            status: 200,
                            statusText: 'OK',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });

                    }
                    case 'PDF-VIEW': {
                        const pdfViewBody = parsedOptions.options as IFileCrudPdfViewOperationOptions;

                        const appRecord = await ApiFileStrategy.getAppRecords(pdfViewBody);
                        const pdfViewResponse = await FileCrudHandler.handlePdfView({ FileRef: appRecord.FileRef });

                        if (!pdfViewResponse) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }

                        return ApiFileStrategy.handleResponse(pdfViewResponse.dataAsBlob, pdfViewResponse.mimeType, "inline;");
                    }

                    case 'PDF-DOWNLOAD': {
                        const pdfDownloadBody = parsedOptions.options as IFileCrudPdfDownloadOperationOptions;
                        const appRecord = await ApiFileStrategy.getAppRecords(pdfDownloadBody);
                        const pdfDownloadResponse = await FileCrudHandler.handlePdfView({ FileRef: appRecord.FileRef });

                        if (!pdfDownloadResponse) {
                            return new Response(JSON.stringify({}), {
                                status: 404,
                                statusText: "Not Found",
                                headers: new Headers({
                                    'Content-Type': 'application/json'
                                })
                            });
                        }

                        return ApiFileStrategy.handleResponse(pdfDownloadResponse.dataAsBlob, pdfDownloadResponse.mimeType, "attachment", pdfDownloadResponse.filename);
                    }

                    case 'UPLOAD-PROGRESS': {
                        return new Response(JSON.stringify({}), {
                            status: 200,
                            statusText: 'OK.',
                            headers: new Headers({
                                'Content-Type': 'application/json'
                            })
                        });
                    }

                    default:
                        throw new Error('Not implemented');
                }
            } catch (reason: any) {
                const stringifiedReason = JSON.parse(JSON.stringify(reason, Object.getOwnPropertyNames(reason)));

                const responseBody = {
                    error: stringifiedReason
                };

                return new Response(JSON.stringify(responseBody), {
                    status: 500,
                    statusText: 'Internal Server Error',
                    headers: new Headers({
                        'Content-Type': 'application/json'
                    })
                });
            }
        }

        // Helper function for retrieval and validation
        static async getAppRecords(options: any): Promise<any> {
            const appRecords = await CrudHandler.handleRetrieve({
                appId: options.appId,
                objectStoreId: options.objectStoreId,
                appIdOverride: options.appIdOverride,
                databaseIdOverride: options.databaseIdOverride ?? "DEFAULT",
                objectStoreIdOverride: options.objectStoreIdOverride,
                fields: [{ name: "FileRef" }],
                indexedDbWhereExpression: {
                    type: 'equals',
                    keyPath: 'PrimKey',
                    key: options.primKey
                }
            });

            if (appRecords.length !== 1) {
                return null;
            }

            return appRecords[0];
        }

        // Helper function to handle responses
        static handleResponse(data: Blob, mimeType: string, disposition: string, filename?: string) {
            let headers = {
                "Content-Type": mimeType,
                "Content-Disposition": disposition
            };

            if (filename) {
                headers["Content-Disposition"] += `; filename="${filename}"`;
            }

            return new Response(data, {
                headers: headers,
                status: 200,
                statusText: 'OK'
            });
        }

        static createNewFileRecord(primKey: string, uploadBody: any, originalRef?: string) {
            return {
                PrimKey: primKey,
                MimeType: uploadBody.mimeType,
                Data: uploadBody.data,
                FileSize: uploadBody.filesize,
                FileName: uploadBody.filename,
                Extension: uploadBody.extension,
                PdfRef: uploadBody.pdfRef,
                ThumbnailRef: uploadBody.thumbnailRef,
                OriginalRef: originalRef,
                appID: uploadBody.appID
            };
        }

        static createInitiateRecord(options: IApiFileRequestChunkUploadInitiateOperationOptions, primKey?: string) {
            try {
                // Validate the request data
                if (!options.FileName) {
                    throw new Error("No filename provided");
                }

                if (options.FileSize === 0) {
                    throw new Error("Filesize not provided");
                }

                if (!options.viewName) {
                    throw new Error("No viewname provided");
                }

                const updateFields: Record<string, any> = {
                    Updated: new Date().toISOString(),
                };

                if (options.values) {
                    for (const [key, value] of Object.entries(options.values)) {
                        updateFields[key] = value;
                    }
                }

                const isUpdate = !!primKey;

                if (!isUpdate) {
                    // This block handles the creation of a new record
                    updateFields["FileName"] = options.FileName;
                    updateFields["FileSize"] = options.FileSize;
                    updateFields["PrimKey"] = self.crypto.randomUUID();
                    updateFields["FileRef"] = self.crypto.randomUUID();
                } else {
                    if (options.values) {
                        for (const [key, value] of Object.entries(options.values)) {
                            updateFields[key] = value;
                        }
                    }
                }

                return updateFields;
            } catch (error) {
                console.error('Error during chunk initiation:', error);
                throw error;
            }
        }

        static createNewAppRecord(filePrimKey: string, uploadBody: any, appPrimKey: string) {
            return {
                appId: uploadBody.appId,
                dataObjectId: uploadBody.objectStoreId,
                appIdOverride: uploadBody.appIdOverride,
                databaseIdOverride: uploadBody.databaseIdOverride ?? "DEFAULT",
                objectStoreIdOverride: uploadBody.objectStoreIdOverride,
                providedRecord: {
                    FileRef: filePrimKey,
                    FileName: uploadBody.filename,
                    Extension: uploadBody.extension,
                    FileSize: uploadBody.filesize,
                    PrimKey: appPrimKey,
                    ...uploadBody.extraValues
                }
            };
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.strategies.api.file.strategy.d.ts')>({ ApiFileStrategy, self });
})();
